import React from 'react'

const Credentials = () => {
  return (
    <div className="credentials">
      <div className="content">
        <p> 
          Dirk König<br />
          Creative Direction & Design<br />
          Portfolio on request 
        </p>
        <p>
          <a href="mailto:hello@dirkkoenig.com">hello@dirkkoenig.com</a>
        </p>
        <p>
          Gleuelerstr. 100<br />
          50931 Köln
        </p>
        <p>
          +49 171 4743329
        </p>
        <p>
          Have a nice day.
        </p>
      </div>
    </div> 
  );
};

export default Credentials;